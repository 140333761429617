import { Grid } from '@mui/material';
import { useEffect } from 'react';

import { FormCheckbox } from '../../../components';
//import { UpperUserRole, UserRole } from 'enums';
import { UseUserFormReturn } from '../hooks/useUserForm';
import FormSection from '../layout/FormSection';
//import { ROLE_EMPLOYMENT_STATUS_MAP } from '../maps';

import strings from "../../../configs/strings";
import { UpperUserRole, UserRole } from 'enums';

interface IUserOptionalAttributesSectionProps {
  control: UseUserFormReturn['control'];
  setValue: UseUserFormReturn['setValue'];
  watch: UseUserFormReturn['watch'];
}

const isUpperRole = ( role: UserRole) :boolean => {
  const upperRoles = Object.values(UpperUserRole) as string[];
  return upperRoles.includes(role.toString());
  //return false
}

const UserOptionalAttributesSection: React.FC<IUserOptionalAttributesSectionProps> = ({
  control,
  setValue,
  watch,
}) => {
  //const is_analytics_user_value = watch('is_analytics_user');
  //console.log("========== is_analytics_user: " + is_analytics_user_value)
  const role = watch('role');
  useEffect(() => {
    //setValue('employment_status', ROLE_EMPLOYMENT_STATUS_MAP[role]);
    
  }, [setValue]);

  return (
    
    <FormSection title="Other Attributes">
        <Grid item xs={6}>
          <FormCheckbox
            control={control}
            name="is_analytics_user"
            label={strings.get('label.is-analytics-user')}
          />
          {isUpperRole(role) && (
            <FormCheckbox
              control={control}
              name="is_designated_operator"
              label={strings.get('label.is-designated-operator')}
            />
          )}
        </Grid>
    </FormSection>
  );
};

export default UserOptionalAttributesSection;
