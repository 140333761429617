import {
  AccountCircle,
  AssistWalker,
  SupportAgent,
  PersonOff,
  SvgIconComponent,
} from '@mui/icons-material';

import { AccountType, AccountStatus, UserRole, AdminRole, SupportRole } from 'enums';
import UsersPage from 'modules/Dashboard/pages/UsersPage';
import LoginPage from 'modules/Auth/pages/LoginPage';
import SupportsPage from 'modules/Dashboard/pages/SupportsPage';
import UserFormPage from 'modules/Forms/pages/UserFormPage';
import SupportFormPage from 'modules/Forms/pages/SupportFormPage';
import TestEmailFormPage from 'modules/Forms/pages/TestEmailFormPage';
// import UpdateUserPage from 'modules/Auth/pages/UpdateUserPage';
import UpdateUserForm from 'modules/Auth/forms/UpdateUserForm';
import DownloadDocumentPage from 'modules/Dashboard/pages/DownloadDocumentPage';
import strings from "../strings";
import SyncData from "modules/Dashboard/pages/SyncData";

interface IRoute {
  path: string;
  roles: (AccountType | UserRole | AdminRole | SupportRole)[];
  label?: string;
  componentProps?: Record<string, any>;
  hidden?: boolean;
  Component: React.FC<any>;
  Icon?: SvgIconComponent;
}

export const routes: IRoute[] = [
  {
    path: '/',
    label: strings.get('menu.home'),
    roles: [AccountType.admin, AccountType.lowerUser, AccountType.upperUser, AccountType.support],
    componentProps: { accountStatus: AccountStatus.active, key: 0 },
    Component: LoginPage,
    Icon: AccountCircle,
    hidden:true
  },
  {
    path: '/active-users',
    label: strings.get('menu.cafe-users'),
    roles: [AccountType.admin, AccountType.upperUser],
    componentProps: { accountStatus: AccountStatus.active, key: 0 },
    Component: UsersPage,
    Icon: AccountCircle,
  },
  {
    path: '/deactivated-users',
    label: strings.get('menu.deactivated-cafe-users'),
    roles: [AccountType.admin, AccountType.upperUser],
    componentProps: { accountStatus: AccountStatus.deactivated, key: 1 },
    Component: UsersPage,
    Icon: PersonOff,
  },
  {
    path: '/active-supports',
    label: strings.get('menu.support-center-users'),
    roles: [AccountType.admin],
    componentProps: { accountStatus: AccountStatus.active },
    Component: SupportsPage,
    Icon: SupportAgent,
  },
  {
    path: '/deactivated-supports',
    label: strings.get('menu.deactivated-support-center-users'),
    roles: [AccountType.admin],
    componentProps: { accountStatus: AccountStatus.deactivated },
    Component: SupportsPage,
    Icon: AssistWalker,
  },
  {
    path: '/user/create',
    roles: [AccountType.admin, AccountType.upperUser],
    Component: UserFormPage,
    hidden: true,
  },
  {
    path: '/user/update/:userId',
    roles: [AccountType.admin, AccountType.upperUser],
    Component: UserFormPage,
    hidden: true,
  },
  {
    path: '/support/create',
    roles: [AccountType.admin],
    Component: SupportFormPage,
    hidden: true,
  },
  {
    path: '/support/update/:userId',
    roles: [AccountType.admin],
    Component: SupportFormPage,
    hidden: true,
  },
  {
    path: '/admin/test-email',
    roles: [AccountType.admin],
    Component: TestEmailFormPage,
    hidden: true,
  },
  {
    path: '/sync-data',
    roles: [AccountType.admin],
    Component: SyncData,
    hidden: true,
  },
  {
    path: '/update-profile',
    label: strings.get('menu.update-password'),
    roles: [AccountType.admin, AccountType.lowerUser, AccountType.upperUser, AccountType.support],
    Component: UpdateUserForm,
    hidden: true,
  },
  {
    path: '/documents',
    label: strings.get('menu.documents'),
    roles: [UserRole.franchisee],
    Component: DownloadDocumentPage,
  },
];
