import { useQuery } from '@tanstack/react-query';

import { userService } from 'services';
 import { userDefaultValues } from '../constants';

const fetchUser = async ({ userId, doNotFetch }) => {
  if (!userId) return Promise.resolve(userDefaultValues);
  //if (!userId) return null;
  return doNotFetch === true ? null : await userService.fetchUser({ userId });
};

interface IUseGetUserOptions {
  // should be undefined when creating a user to set empty default values inside form
  userId: string | undefined;
  // added doNotFetch, because on the update-profile page, the app gets its data from the /me endpoint
  // fetching gets it from dashboard/users which is only needed when editing someone else's profile
  doNotFetch?: boolean | false;
}

const useGetUser = ({ userId, doNotFetch }: IUseGetUserOptions) => {
  const { data, error, isLoading, isFetching } = useQuery(
    ['user', userId],
    () => fetchUser({ userId, doNotFetch }),
    {
      placeholderData: userDefaultValues,
      refetchOnWindowFocus: false,
    }
  );

  return { data, error, loading: isLoading || isFetching };
};

export default useGetUser;
