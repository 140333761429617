import { UserRole, EmploymentStatus } from 'enums';

import { IUserFormFields } from './interfaces';

export const userDefaultValues: IUserFormFields = {
  uuid: '',
  firstname: '',
  lastname: '',
  email: '',
  role: '' as UserRole,
  employment_status: '' as EmploymentStatus,
  decentralized_cafes: '',
  is_head_of_decentralized_cafes: false,
  is_designated_operator: false,
  is_analytics_user: false,
  unitsDisplay: [],
  cafe_number: [],
  bulk_units_display: '',
  is_admin: false,
  schoox_id: null,
};
