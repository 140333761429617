import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { IUserProfileFormFields } from '../interfaces';
import useGetUser from '../queries/useGetUser';
import { useEffect } from 'react';

import { newPassword } from '../Yup/commonValidators';

interface UseUpdateUserProfileFormInterface {
  userId: string;
}

const schema = yup.object().shape(
  {
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    email: yup.string().email().required(),
    newPassword,
    confirmPassword: yup
      .string()
      .optional()
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
  },
  [['newPassword', 'newPassword']],
);

const useUpdateUserProfileForm = ({ userId }: UseUpdateUserProfileFormInterface) => {
  const { data, loading } = useGetUser({ userId, doNotFetch:true});

  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    watch,
    getValues,
  } = useForm<IUserProfileFormFields>({
    defaultValues: data,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!loading) {
      reset(data);
    }
  }, [data, loading, reset]);

  const isEdit = !!userId;

  return {
    control,
    errors,
    dirtyFields,
    isEdit,
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    watch,
    getValues,
  };
};

export type UseUpdateUserProfileFormReturn = ReturnType<typeof useUpdateUserProfileForm>;

export default useUpdateUserProfileForm;
