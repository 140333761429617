import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Box, Grid, CssBaseline, Paper } from '@mui/material';

import { authAtom } from 'atoms';
import LoginForm from '../forms/LoginForm';
import ResetPasswordForm from '../forms/ResetPasswordForm';
import { FORMS } from '../enums';
import { AccountType } from 'enums';
//import { GridRow } from '@mui/x-data-grid';
import client from 'configs/client';

import tscUpWhite from './../../../assets/tsc-up-favicon_white.png';
import tscOrangeGreenLogo from './../../../assets/TSC-2015_Logo_process.png';


const LoginPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isAuth, user } = useRecoilValue(authAtom);
  const [form, setForm] = useState(FORMS.LOGIN);

  const isLogin = form === FORMS.LOGIN;
  const isPasswordReset = form === FORMS.RESET_PASSWORD;

  useEffect(() => {
    if (isAuth) {
      // if we got here from a SAMLRequest, redirect back
      if(searchParams.get("SAMLRequest")){
        const path = searchParams.get("returnUrl") || "/sso/signon";
        client.redirectWithPost(path, {SAMLRequest: searchParams.get("SAMLRequest"), RelayState: searchParams.get("RelayState")});
        return;
      }
      if(searchParams.get("returnUrl")){
        client.redirectWithGet(searchParams.get("returnUrl"));
        return;
      }
      //This circumvents the / route since that needs to be accessible to all roles
      //redirect to the role's "home page" instead of '/'
      if (
        user.accountType === AccountType.lowerUser
        // user.accountType === AccountType.upperUser
      ) {
        navigate('/update-profile');
      } else if (user.accountType === AccountType.support) {
        // support users originally couldn't login
        // now we have to let them login for SSO, but if we do that, we may
        // as well let them access their profile
        navigate('/update-profile');
      } else {
        navigate('/active-users');
      }
    }
  }, [isAuth, navigate, user, searchParams]);

  return (

    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid item xs={12} sx={{ backgroundColor: 'customColors.punch'}}>
        <Box
          component="img"
          alt="tsc up white logo"
          sx={{ display: 'block', height: 30}}
          src={tscUpWhite}
        />
      </Grid>
      {isPasswordReset && (
        <Grid item
          xs={12}
          component={Paper} elevation={0} square padding={5} sx={{ height: '100vh' }}>
          <Box
            sx={{
              my: 10,
              mx: 10,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {isPasswordReset && <ResetPasswordForm setForm={setForm} />}
          </Box>
        </Grid>
      )}
      {isLogin && (
        <Grid container sx={{ height: '100vh' }}>
          <Grid
            item
            xs={false}
            sm={6}
            md={6}
            sx={{
              backgroundImage: 'url("kind people cup.jpg")',
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}></Grid>
          <Grid item direction={'column'} justifyContent={'space-between'}
            xs={12}
            sm={6}
            md={6}
            component={Paper} elevation={0} square padding={5}>
            <Box
              sx={{
                mx: 10,
                marginTop:10,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <LoginForm setForm={setForm} />
            </Box>
            <Box
              sx={{
                display: 'flex', 
                marginTop:'25%',
                width: '100%',
                alignSelf: "flex-end",
                flexDirection: 'column',
                alignItems: "end",
                justifyContent: "end"
              }}>
              <Box
                component="img"
                alt="tsc orange green logo"
                sx={{ display: 'block', width: 200, }}
                src={tscOrangeGreenLogo}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default LoginPage;
