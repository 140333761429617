import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import { UserRole, EmploymentStatus } from 'enums';
import { IUserFormFields } from '../interfaces';
import useGetUser from '../queries/useGetUser';
import { useEffect } from 'react';

import { newPassword } from '../Yup/commonValidators';

const schema = yup.object().shape(
  {
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    email: yup.string().email().required(),
    role: yup.string().equals(Object.values(UserRole)).required(),
    employment_status: yup
      .string()
      .equals(Object.values(EmploymentStatus))
      .required(),
    // decentralized_cafes: yup
    //   .string()
    //   .nullable()
    //   .when('role', {
    //     is: (role) => role === UserRole.franchisee,
    //     then: (schema) => schema.optional().nullable(),
    //   }),
    is_head_of_decentralized_cafes: yup
      .boolean()
      .when(['role', 'decentralized_cafes'], {
        is: (role, decentralized_cafes) =>
          role === UserRole.franchisee && decentralized_cafes,
        then: (schema) => schema.optional().nullable(),
      }),
    is_analytics_user: yup
      .boolean()
      .optional(),
    unitsDisplay: yup.array().of(yup.string()).required(),
    //cafe_number: yup.array().of(yup.string()).required(),
    newPassword,
    confirmPassword: yup
      .string()
      .optional()
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
  },
  [['newPassword', 'newPassword']],
);

const useUserForm = () => {
  const { userId } = useParams<'userId'>();
  const { data, loading } = useGetUser({ userId });

  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    watch,
    getValues,
  } = useForm<IUserFormFields>({
    defaultValues: data,
    resolver: yupResolver(schema),
  });
  
  useEffect(() => {
    if (!loading) {
      reset(data);
    }
  }, [data, loading, reset]);

  const isEdit = !!userId;

  return {
    control,
    errors,
    dirtyFields,
    isEdit,
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    watch,
    getValues,
  };
};

export type UseUserFormReturn = ReturnType<typeof useUserForm>;

export default useUserForm;
