import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosError,
  AxiosResponse,
} from 'axios';
import * as R from 'ramda';

import { cookieService } from 'services';
import { IResponseBody, IError } from 'interfaces';

interface ISpecificFieldError {
  [key: string]: {
    msg: string;
  };
}

interface ICommonError {
  message?: string;
}

interface IResponseError {
  errors: ISpecificFieldError & ICommonError;
}

enum METHOD {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

type URL = string;
type Data = any;
type Config = AxiosRequestConfig;

class Client {
  private client: AxiosInstance;

  constructor() {
    if (typeof process.env.REACT_APP_PROTOCOL === 'undefined') {
      console.error('No value for REACT_APP_PROTOCOL found in .env file.');
    }
    if (typeof process.env.REACT_APP_HOST === 'undefined') {
      console.error('No value for REACT_APP_HOST found in .env file.');
    }
    const client = axios.create({
      baseURL: `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}:${process.env.REACT_APP_NODE_PORT || 9000}/api`,
    });

    client.interceptors.request.use((request) => {
      if (request?.headers) {
        const { accessToken } = cookieService.getTokens();
        if (accessToken) request.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      return request;
    });

    this.client = client;
  }

  public get = async <T = IResponseBody<any>>(
    url: URL,
    config?: Config,
  ): Promise<T> => {
    return await this.request(METHOD.GET, url, undefined, config);
  };

  public post = async <T = IResponseBody<any>>(
    url: URL,
    data?: Data,
    config?: Config,
  ): Promise<T> => {
    return await this.request(METHOD.POST, url, data, config);
  };

  public put = async <T = IResponseBody<any>>(
    url: URL,
    data?: Data,
    config?: Config,
  ): Promise<IResponseBody<T>> => {
    return await this.request(METHOD.PUT, url, data, config);
  };

  public delete = async <T = IResponseBody<any>>(
    url: URL,
    config?: Config,
  ): Promise<IResponseBody<T>> => {
    return await this.request(METHOD.DELETE, url, undefined, config);
  };

  private request = async (
    method: METHOD,
    url: URL,
    data?: Data,
    config?: Config,
  ) => {
    if (method in this.client) {
      const requestArgs = [url, data, config].filter((value) => value !== undefined);

      try {
        // @ts-ignore
        const response = await this.client[method](...requestArgs);
        return this.getResponseData(response);
      } catch (error) {
        if (error.response.status === 401) {
          try {
            await this.refreshToken();

            // @ts-ignore
            const response = await this.client[method](...requestArgs);
            return this.getResponseData(response);
          } catch (error) {
            throw this.formatError(error);
          }
        }
        throw this.formatError(error);
      }
    } else {
      throw new Error(`Method: "${method}" doesn't exists on the client instance`);
    }
  };

  private refreshToken = async () => {
    try {
      const { refreshToken } = cookieService.getTokens();
      if (!refreshToken) {
        throw Object.assign(new Error(), { status: 401 });
      }

      const {
        data: { data },
      } = await this.client.put('/auth/refreshToken', { refreshToken });
      const { accessToken, expiresIn } = data;

      cookieService.setAuthCookie({ accessToken, expiresIn });
    } catch (error) {
      // refresh token expired
      if (error?.response.status === 401) {
        console.log("Deleting Auth Cookies!!!")
        cookieService.deleteAuthCookies();     
        
        //Now force redirect to the root 
        //to discontinue request processing
        //and have user login again
        return window.location.href = '/';
      }
    }
  };

  private getResponseData(response: AxiosResponse) {
    return R.pathOr(null, ['data'], response);
  }

  private formatError(error: AxiosError<IResponseError>): IError {
    const { errors } = error.response.data;
    return Object.entries(errors).reduce((errors, [key, value]) => {
      errors[key] = R.pathOr(value, ['msg'], value);
      return errors;
    }, {});
  }

  public parseQueryString(path:string):any {
    // Remove leading '?' if present
    const i = path.indexOf("?");
    if(i<0) return {};

    const sanitizedQuery = path.slice(i+1)
   
    // Handle empty query string
    if (!sanitizedQuery) return {};
   
    // Split on '&' and convert to object
    const params = sanitizedQuery.split('&').reduce((params, pair) => {
      // Split each pair on '='
      const [key, value] = pair.split('=');
      
      // Decode URI components and add to object
      // If no value provided, set as empty string
      params[decodeURIComponent(key)] = value 
        ? decodeURIComponent(value)
        : '';
        
      return params;
    },{});
    console.log("params are", params);
    return params;
  }

  public redirect(method:'POST'|'GET', path:string, data:object){
    const baseUrl:string = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}:${process.env.REACT_APP_NODE_PORT || 9000}`;
    const url:string = baseUrl + path;
    const form = document.createElement("form");
    form.setAttribute("method", method);
    form.setAttribute("action", url);
    for(const prop in data){
      const input = document.createElement("input");
      input.type="hidden";
      input.setAttribute("id", prop);
      input.setAttribute("name", prop);
      input.value = data[prop];
      form.appendChild(input);
    }
    document.body.appendChild(form);
    form.submit();
  }

  public redirectWithPost(path:string, data:object){
    return this.redirect('POST', path, data);
  }
  public redirectWithGet(path:string, data?:object){
    let dataObj:any = {
      ...this.parseQueryString(path),
      ...data
    };
    return this.redirect('GET', path.replace(/\?.*/,''), dataObj);
  }

}

const client = new Client();

export default client;
